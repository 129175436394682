<template>
  <div class="info-box">
    <img src="@/assets/img/icons/info.svg" alt="info icon" />
    <p>
      {{ info }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.info-box {
  font-size: 1.6rem;
  background-color: #e6fcf5;
  color: #087f5b;
  border: 1px solid #c3fae8;
  border-radius: 9px;
  padding: 1.2rem;
  display: flex;
  gap: 7px;
  line-height: 150%;
  font-weight: 500;
  align-items: center;
  max-width: 100%;
  width: auto;
  word-wrap: break-word;
}
</style>
